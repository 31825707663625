import { ArrowLeftStartOnRectangleIcon } from "@heroicons/react/24/solid";
import { useStoreActions, useStoreState } from "../store/hooks";
import Barcode from "react-barcode";

export default function LibraryCard() {
  const user = useStoreState((state) => state.user);
  const logout = useStoreActions((actions) => actions.logout);

  if (!user) {
    logout();
    return null;
  }

  function renderBarcode() {
    if (!user) return null;

    const number = user.number.split("/");
    const value = number[0] + number[1].padStart(6, "0");

    return (
      <div className="w-full flex justify-center">
        <Barcode
          value={value}
          displayValue={false}
          format="codabar"
          margin={0}
          width={2.5}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-80">
      <div className="mb-12">
        <img
          src="/img/logo.png"
          className="mx-auto max-w-48"
          alt="Gradska knjižnica Poreč"
        />
      </div>

      <div className="bg-white rounded-md p-4 flex flex-col items-center">
        <div className="font-semibold text-xl">
          {user.name} {user.surname}
        </div>
        <div className="text-lg">{user.number}</div>
        <div className="my-4">{renderBarcode()}</div>
        <div className="text-center">
          Vrijedi do
          <br />
          <span className="font-semibold">
            {new Date(user.expiresAt).toLocaleDateString("hr", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
        </div>
      </div>

      <button
        className="mt-16 uppercase font-semibold self-center flex gap-2 text-white"
        onClick={() => logout()}
      >
        <ArrowLeftStartOnRectangleIcon className="h-6 w-6 text-white" />
        Odjava
      </button>
    </div>
  );
}
