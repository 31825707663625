import {
  Action,
  Computed,
  Thunk,
  action,
  computed,
  createStore,
  persist,
  thunk,
} from "easy-peasy";

interface UserModel {
  name: string;
  surname: string;
  number: string;
  expiresAt: string;
}

class User implements UserModel {
  name: string;
  surname: string;
  number: string;
  expiresAt: string;
  constructor(source: any = {}) {
    if (typeof source === "string") {
      source = JSON.parse(source);
    }
    this.name = source.name;
    this.surname = source.surname;
    this.number = source.number;
    this.expiresAt = source.expiresAt || source.expires_at;
  }
}

export interface StoreModel {
  user?: User;
  authToken?: string;
  authTokenExpiresAt?: string;
  attemptLogin: Thunk<StoreModel, { username: string; password: string }>;
  loginSuccess: Action<
    StoreModel,
    { user: User; authToken: string; authTokenExpiresAt: string }
  >;
  logout: Action<StoreModel>;
  loggedIn: Computed<StoreModel, boolean>;
  hasValidToken: Computed<StoreModel, boolean>;
}

const store = createStore<StoreModel>(
  persist(
    {
      user: undefined,
      authToken: undefined,
      authTokenExpiresAt: undefined,
      attemptLogin: thunk(async (actions, payload) => {
        const { username, password } = payload;
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "applications/json",
            },
            body: JSON.stringify({ username, password }),
          }
        );
        if (response.status === 401) {
          throw new Error("Neispravan broj iskaznice ili PIN");
        } else if (!response.ok) {
          throw new Error("Greška u komunikaciji sa serverom");
        }
        const {
          access_token: authToken,
          expires_at: authTokenExpiresAt,
          user,
        } = await response.json();
        actions.loginSuccess({ authToken, authTokenExpiresAt, user });
      }),
      loginSuccess: action((state, payload) => {
        const { user, authToken, authTokenExpiresAt } = payload;
        state.user = new User(user);
        state.authToken = authToken;
        state.authTokenExpiresAt = authTokenExpiresAt;
      }),
      logout: action((state) => {
        state.user = undefined;
        state.authToken = undefined;
        state.authTokenExpiresAt = undefined;
      }),
      hasValidToken: computed(
        (state) =>
          !!state.authToken &&
          !!state.authTokenExpiresAt &&
          new Date(state.authTokenExpiresAt) > new Date()
      ),
      loggedIn: computed((state) => state.hasValidToken && !!state.user),
    },
    {
      storage: "localStorage",
    }
  )
);

export default store;
