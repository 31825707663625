import { LockClosedIcon, UserIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useStoreActions } from "../store/hooks";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Login() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const attemptLogin = useStoreActions((actions) => actions.attemptLogin);

  async function callbackOnSubmit() {
    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    if (!trimmedUsername || !trimmedPassword) {
      alert("Unesite broj iskaznice i PIN");
      return;
    }

    setLoggingIn(true);
    try {
      await attemptLogin({
        username: trimmedUsername,
        password: trimmedPassword,
      });
    } catch (e: any) {
      alert(e.message || "Neuspješna prijava");
    }
    setLoggingIn(false);
  }

  return (
    <div className="flex flex-col w-80">
      <div className="mb-12">
        <img
          src="/img/logo.png"
          className="mx-auto max-w-48"
          alt="Gradska knjižnica Poreč"
        />
      </div>
      <div className="bg-white p-3 flex rounded-t-md items-center">
        <input
          type="text"
          placeholder="Broj iskaznice"
          className="border-0 grow p-1 focus:outline-none font-semibold text-lg"
          onChange={(e) => setUsername(e.target.value)}
        />
        <UserIcon className="h-6 w-6 text-gray-400" />
      </div>
      <div className="bg-white p-3 flex rounded-b-md border-t items-center">
        <input
          type="password"
          placeholder="PIN"
          className="border-0 grow p-1 focus:outline-none font-semibold text-lg"
          onChange={(e) => setPassword(e.target.value)}
        />
        <LockClosedIcon className="h-6 w-6 text-gray-400" />
      </div>
      {loggingIn ? (
        <LoadingSpinner />
      ) : (
        <button
          type="button"
          className="bg-white p-3 mt-2 rounded-md disabled:opacity-80 uppercase font-semibold relative"
          disabled={loggingIn}
          onClick={callbackOnSubmit}
        >
          Prijava
        </button>
      )}
    </div>
  );
}
