import "./App.css";
import { useStoreState } from "./store/hooks";
import Login from "./pages/Login";
import LibraryCard from "./pages/LibraryCard";
import { useStoreRehydrated } from "easy-peasy";
import LoadingSpinner from "./components/LoadingSpinner";
import { useEffect } from "react";

function App() {
  const loggedIn = useStoreState((state) => state.loggedIn);
  const isRehydrated = useStoreRehydrated();
  // const [isInstalled, setIsInstalled] = useState(false);
  // const installPrompt = useRef<any>(null);

  useEffect(() => {
    // async function checkInstall() {
    //   const isApp = window.matchMedia("(display-mode: standalone)").matches;
    //   const installedApps = await (
    //     window as any
    //   ).navigator.getInstalledRelatedApps();
    //   setIsInstalled(isApp || installedApps.length > 0);
    // }
    // checkInstall();
    // window.addEventListener("beforeinstallprompt", (e: any) => {
    //   e.preventDefault();
    //   installPrompt.current = e;
    // });
  }, []);

  // function installApp() {
  //   installPrompt.current.prompt();
  //   installPrompt.current.userChoice.then((choiceResult: any) => {
  //     if (choiceResult.outcome === "accepted") {
  //       setIsInstalled(true);
  //     }
  //   });
  // }

  return (
    <div className="App">
      {/* {isInstalled ? null : (
        <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 p-4 flex justify-center items-center">
          <div className="flex-grow text-white">
            <div className="text-lg font-semibold">Instalirajte aplikaciju</div>
            <div className="text-sm">
              Dodajte aplikaciju na početni zaslon kako biste joj mogli
              pristupiti brže.
            </div>
          </div>
          <div className="flex-shrink-0">
            <button
              className="bg-primary text-white rounded-md px-4 py-2"
              onClick={() => {}}
            >
              Instaliraj
            </button>
          </div>
        </div>
      )} */}
      {!isRehydrated ? (
        <LoadingSpinner />
      ) : loggedIn ? (
        <LibraryCard />
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
